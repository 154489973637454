
import Order from '@/services/order/Order'
import DateUtils from '@/utils/DateUtils'
import { Options, Vue, setup, prop } from 'vue-class-component'

import OrderProductListItem from './OrderProductListItem.vue'

class OrderDetailProps {
  order = prop<Order>({})
}

@Options({
  name: 'order-detail',
  components: {
    OrderProductListItem
  }
})
export default class OrderDetailComponent extends Vue.with(OrderDetailProps) {
  context = setup(() => {
    const getFullAddress = (order: Order): string => {
      if (!order) {
        return ''
      }

      const noInt = order.OrderAddressNoInt ? `INT: ${order.OrderAddressNoInt}` : ''

      return `${order.OrderAddressStreet} ${order.OrderAddressNoExt} ${noInt} ${order.OrderAddressNeighborhood} ${order.OrderAddressZipCode}`
    }

    return {
      getFullAddress,
      ...DateUtils.toLiteral()
    }
  })
}
