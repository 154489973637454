import { toDisplayString as _toDisplayString, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-sm-start my-5" }
const _hoisted_2 = { class: "d-flex flex-row-fluid align-items-start my-lg-0 me-2" }
const _hoisted_3 = { class: "d-flex align-items-center flex-shrink-0 me-4 min-w-65px" }
const _hoisted_4 = { class: "min-w-50px text-center fw-bolder" }
const _hoisted_5 = { class: "flex-grow-1 my-lg-0 my-2 me-2" }
const _hoisted_6 = { class: "text-gray-800 fw-bolder fs-6" }
const _hoisted_7 = { class: "my-2" }
const _hoisted_8 = { class: "d-flex align-items-center flex-shrink-0" }
const _hoisted_9 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("span", _hoisted_4, _toDisplayString(_ctx.product.ProductQuantity), 1)
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode("span", _hoisted_6, _toDisplayString(_ctx.product.ProductName), 1),
          _withDirectives(_createVNode("div", _hoisted_7, [
            _withDirectives(_createVNode("span", null, _toDisplayString(_ctx.product.ProductPromotionQuantity) + "x ", 513), [
              [_vShow, _ctx.product.ProductPromotionQuantity > 0]
            ]),
            _createTextVNode(" Promoción: " + _toDisplayString(_ctx.product.PromotionType?.PromotionTypeName), 1)
          ], 512), [
            [_vShow, _ctx.product.PromotionType]
          ]),
          _withDirectives(_createVNode("div", { class: "my-2" }, _toDisplayString(_ctx.product.ProductComments), 513), [
            [_vShow, _ctx.product.ProductComments]
          ]),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.product.ModifierList, (item, index) => {
            return (_openBlock(), _createBlock("div", {
              class: "text-muted my-1",
              key: index
            }, _toDisplayString(item.ItemQuantity) + "x " + _toDisplayString(item.ItemName), 1))
          }), 128))
        ]),
        _createVNode("div", _hoisted_8, [
          _createVNode("div", _hoisted_9, [
            _createVNode("div", {
              style: _ctx.product.PromotionType ? 'text-decoration: line-through' : ''
            }, " $" + _toDisplayString((_ctx.product.ProductTotal * _ctx.product.ProductQuantity).toFixed(2)), 5),
            _withDirectives(_createVNode("div", null, " $" + _toDisplayString(_ctx.product.ProductFinal.toFixed(2)), 513), [
              [_vShow, _ctx.product.PromotionType]
            ])
          ])
        ])
      ])
    ])
  ]))
}