import { Nullable } from '@/core/utils/CustomTypes'
import authAxios from '@/services/core/AuthAxios'
import store from '@/store'
import { Services } from '@/store/enums/StoreEnums'
import { AllSections } from '../allsections/AllSections'
import allsectionsService from '../allsections/allsections.service'
import ServiceHandler from '../core/ServiceHandler'
import AppWorkspace from '../workspaces/AppWorkspace'
import OrderHistoryResponseData from './responses/OrderHistoryResponseData'
import OrderResponseData from './responses/OrderResponseData'

class OrderService {
  getActiveOrderList (branchId?: number) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.ORDER_ACTIVE_LIST, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            BranchId: branchId
          }
        })
        .then(ServiceHandler.checkSuccess<OrderResponseData>(OrderResponseData))
        .catch(ServiceHandler.catchError())
    })
  }

  getHistoryOrderList (
    startDate: string,
    endDate: string,
    branchId?: number,
    search?: string,
    clientPhone?: Nullable<string>,
    size = 50,
    page = 1) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.ORDER_HISTORY_LIST, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            FromDate: startDate,
            UntilDate: endDate,
            BranchId: branchId,
            Search: search,
            ClientPhone: clientPhone,
            Query: {
              Limit: size,
              Offset: (page - 1) * size
            }
          }
        })
        .then(ServiceHandler.checkSuccess<OrderHistoryResponseData>(OrderHistoryResponseData))
        .catch(ServiceHandler.catchError())
    })
  }

  sendOrderInfo (chatId: Nullable<number>, orderIds: number[]) {
    return allsectionsService.get().then((allSections: AllSections) => {
      const workspace = store.getters.currentWorkspace as AppWorkspace
      const serviceUrl = allSections.getServiceUrl(Services.SEND_ORDER_INFO, workspace)

      return authAxios
        .post(serviceUrl, {
          Data: {
            ChatId: chatId,
            OrderList: orderIds.map(id => {
              return {
                OrderId: id
              }
            })
          }
        })
        .then(ServiceHandler.checkSuccessEmpty())
        .catch(ServiceHandler.catchError())
    })
  }
}

export default new OrderService()
