
import { onMounted } from 'vue'
import { DrawerComponent } from '@/assets/ts/components/_DrawerOptions'

import { Options, prop, setup, Vue } from 'vue-class-component'
import Order from '@/services/order/Order'
import OrderDetail from '../OrderDetail.vue'

class OrderDetailDrawerProps {
  order = prop<Order>({})
}

@Options({
  name: 'order-detail-drawer',
  components: {
    OrderDetail
  }
})
export default class OrderDetailDrawerComponent extends Vue.with(OrderDetailDrawerProps) {
  context = setup(() => {
    onMounted(() => {
      DrawerComponent.reinitialization()
    })

    return {

    }
  })
}
