
import { CartProduct } from '@/services/cart/Cart'
import { Options, prop, setup, Vue } from 'vue-class-component'
class OrderProductListItemProps {
  product = prop<CartProduct>({})
}

@Options({
  name: 'order-product-list-item',
  components: {}
})
export default class OrderProductListItemComponent extends Vue.with(
  OrderProductListItemProps
) {
  context = setup(() => {
    return {

    }
  })
}
